
import './App.css';
import {Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import CreatePlate from './CreatePlate';
import Plates from './Plates';
import Landing from './Landing';
import Plate from './Plate';
import { useAuth0, User } from "@auth0/auth0-react";
import Profile from './Profile';
import MyPlates from './MyPlates';
import MyAuctions from './MyAuctions';
import MyWatchlist from './MyWatchlist';
import PlatesAdmin from './PlatesAdmin';
import PlateAdmin from './PlateAdmin';
import About from './About';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import {useLayoutEffect} from 'react';
import _404 from './404'
import { ErrorBoundary } from "react-error-boundary";
import Error from './Error';
import FAQ from './FAQ';
import Cookies from './Cookies';
import Legal from './Legal';
import DataProtection from './DataProtection';
import Contact from './Contact';
import Support from './Support';
import ReportBug from './ReportBug';
import ReportSubmitted from './ReportSubmitted';
import ComingSoon from './ComingSoon';
import CookieConsent from 'react-cookie-consent';
import { useEffect } from 'react';
import Valuation from './Valuation';
import ValuationSubmitted from './ValuationSubmitted';
import ValuationsAdmin from './ValuationsAdmin';
import ValuationAdmin from './ValuationAdmin';
import UsersAdmin from './UsersAdmin';

function App() {
  const { isAuthenticated, user } = useAuth0();
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 

  useEffect(() => {
    document.title = "MyRegPlates";
  }, [])
  
  
  return (
    <div id="wrapper">
      <ErrorBoundary fallback={<Error/>}>
          <Wrapper>
            <Routes>
              <Route path="*" element={<Landing/>}/>
            </Routes>
          </Wrapper>
      </ErrorBoundary>
    </div>
  );
}

export default App;
