import React, { useEffect }  from 'react'
import './App.css';
import './marquee.css';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth0, User } from "@auth0/auth0-react";

import doc from './res/document-outlined.svg'
import home from './res/home-outlined.svg'
import person from './res/person-multiple-outlined.svg'
import logo from './res/logo.png'
import stars from './res/stars.svg'



function Landing() {
    return (
        <div style={{backgroundColor: 'var(--main-theme-color)', height: '100vh', display: 'flex', alignItems: 'center'}}>
           <img id="logo" src={logo} alt="MyRegPlates" style={{margin: 'auto'}}/>
        </div>
    )
}

export default Landing